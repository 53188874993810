
//.body{
	//.main{
		.menu-block {
			
			h1 {
				color: @grey-dark;
				//background-color: red;
			
			}
			h2 {
			
			}
			
			.menu-item-block {
				&.course-title {
					
				}
				&.course-subtitle {
					
				}
				&.menu-item {
					h4 {
						
					}
					h5 {
						
					}
					.menu-item-notes {
						&.menu-item-notes-vegeterian {
							
						}
						&.menu-item-notes-vegan {
							
						}
						&.menu-item-notes-nuts {
							
						}
					}
				}
				&.note {
					
				}
			}
		}
	//}
//}
