
//@xs-min: 480px;
//@sm-min: 768px;
//@md-min: 992px;
//@lg-min: 1200px;




body {


	@media screen and (max-width: @screen-sm-max) {
		.wrap {
			.content {
				margin-top: 98px;
			}
		}
	}
	
	@media screen and (max-width: @screen-xs-max) {
		.wrap {
			.content {
				margin-top: 0;
			}
		}
	}

		

	// Headder and Nav 
	
	.banner {
		height: 44px;

		@media screen and (max-width: @screen-sm-max) {
			height: 100px;
		}
		@media screen and (max-width: @screen-xs-max) {
			height: 44px;
		}

		.navbar-header {
			text-align: left;

			
			@media screen and (max-width: @screen-md-max) {
				text-align: right;
			}
			@media screen and (max-width: @screen-sm-max) {
				text-align: left;
				float: none;
			}
			@media screen and (max-width: @screen-xs-max) {
				text-align: center;
				float: none;
			}

			a.navbar-brand {
				display: block;
				float: none;
				text-align: center;
				font-size: 1.8em;
			
				
				@media screen and (max-width: @screen-sm-max) {					
					display: block;
					float: none;
					text-align: center;
					font-size: 1.5em;
					
				}
				@media screen and (max-width: @screen-xs-max) {					
					display: block;
					float: none;
					text-align: left;
					font-size: 1.3em;
					text-indent: 0px;
				}
			}
			#menu-primary-navigation {
				margin: 0 auto;


				@media screen and (min-width: @screen-sm) {
					.dropdown-menu li:hover .sub-menu {
					    visibility: visible;
					}
					
					.dropdown:hover .dropdown-menu {
					    display: block;
					}
				}

			}
		}
		
		.navbar-nav a {

			@media screen and (max-width: @screen-md-max) {
				padding-left: 8px;
				padding-right: 10px;
			}
			@media screen and (max-width: @screen-sm-max) {
				padding-left: 20px;
				padding-right: 10px;
			}
		}


		.navbar-collapse {
			float: right;	
		}
		
		@media screen and (max-width: @screen-md-max) {	
			.navbar-collapse {
				float: right;	
			}
		}
		@media screen and (max-width: @screen-sm-max) {	
			.navbar-collapse {
				float: none;
				display: inline-block; 
				text-align: center; 
				width: auto;
				//display: table;
			    margin: 0 auto;
			    .navbar-nav {
					float: none;
					margin: 0 auto;
					width: auto;
					display: inline-block;
			    }
			}
		}
		/*
		*/
		@media screen and (max-width: @screen-xs-max) {	
			.navbar-collapse {
				display: block;
				background: #fff;
				&.collapse {
					display: none;
				}
				&.in {
					display: block;
				}
				
			}
		}

	}


	// Main Content

	//----head-image!

	div.head-image .image-con{ 

		@media screen and (max-width: @screen-md-max){
			img {
		    	//margin-top: 5%;
			}
		}

		@media screen and (max-width: @screen-sm-max){
			img {
		    	//margin-top: 10%;
			}
		}
		@media screen and (max-width: @screen-xs-max){
			img {
		    	//margin-top: 7%;
			}
		}	

	}

		// TABBED CONTENT ELEMENT
	
	.page-block {
		&.tabbed-content {
			.row {
				@media screen and (max-width: @screen-xs-max) {	
					.inner {
						padding-left: 20px;
						padding-right: 20px;
						width: auto;
						margin: auto;
					}
				}	
			}
		}	
	}

	// Flexible Footer

	.footer-page{

		@media screen and (max-width: @screen-xs-max) {	
		 	.f-box a{
			    padding: 20px 0 !important;
		 	}
		 	.col-right {
		 		margin: auto;
		 		text-align: center;
		 	}
		}

		@media screen and (max-width: @screen-sm-max) {	
		 	.col-right {
		 		margin: auto;
		 		text-align: center;
		 	}
			#mc_embed_signup{

				input.email {
					background-color: @grey-light !important;
					margin: auto;
					margin-bottom: 10px;
				}
				.button{
					margin:auto 10;
				}	
			}
		}
		@media screen and (max-width: @screen-xs-max) {	
			#mc_embed_signup{

				input.email {
					background-color: @grey-light !important;
					margin: auto !important;
					margin-bottom: 10px;
				}
			}
		}
	}

	// Fixed footer

	.footer-fixed { 
			

				@media screen and (max-width: @screen-md-max) {	
					.btn-primary, .comment-form input[type=submit]{
						//display: none !important;
					    padding-left: 100px;
						padding-right: 0px;
					}
				}

				@media screen and (max-width: @screen-sm-max) {	
					.btn-primary, .comment-form input[type=submit]{
						//display: none;
					    padding-left: 20px!important;
						padding-right: 20px !important;
					}
				}

				@media screen and (max-width: @screen-xs-max) {	
					.ft-right{
						text-align: center !important;
					
						.btn-primary, .comment-form input[type=submit]{
							//display: none;
						    padding-left: 10px !important;
							padding-right: 10px !important;
							letter-spacing: 2px !important;
						}	
					}
				}

	}
	

	.image-grid { 
		//margin-top: 0px;
		
		.ig-block { 

			.ig-inner { 

				.ig-overlay { 

					hr { 

					}

					.ig-overlay-bt { 

						.btn { 

						}
					}
				}

				@media screen and (max-width: @screen-md-max) {	
					.ig-overlay { 

						hr { 

						}

						.ig-overlay-bt { 

							.btn { 

							}
						}
					}
				}
				@media screen and (max-width: @screen-sm-max) {	
					.ig-overlay { 

						hr { 

						}

						.ig-overlay-bt { 

							.btn { 

							}
						}
					}
				}
				@media screen and (max-width: @screen-xs-max) {	

					.ig-overlay { 
						width: 80%;
						font-size: 2em;
						//top: 25%;
						//left: 10%;

						hr { 

						}

						.ig-overlay-bt { 

							.btn { 
								font-size: 12px;
							}
						}
					}
				}	
				
							
			}
			
			
			@media screen and (max-width: @screen-sm-max) {	
				&.col-sm-4 {
					.ig-overlay { 
						width: 80%;
						font-size: 24px;
						//top: 9% !important;
						//left: 10%;
						.btn {
							font-size: 20px;
						}
					}
				}
			}	
			
			@media screen and (max-width: @screen-md-max) {	
				&.col-md-4 {
					.ig-overlay { 
						width: 80%;
						font-size: 24px;
						//top: 9% !important;
						//left: 10%;
						.btn {
							font-size: 20px;
						}
					}
				}
			}	
			
		}
	}
	.container{
		.jumbotron{
			padding-left: 0px;
		    padding-right: 0px;
		}
	}




//end body tag!!
}	



	