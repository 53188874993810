
body {
	background: @body-bg-colour;

/*
	div.bootstrap-datetimepicker-widget {
		position: absolute;
		z-index: 100000 !important;
		display: block;
	}
	.absolutron {
		position: absolute;
		background: #d00;		
	}
*/

	// NAVIGATION:
	
	.banner {
		padding: 0;
		margin: 0;
		margin-bottom: 46px;
		
		ul.nav {
			li {
				a {
					color: @navbar-link-colour;
					background: @navbar-link-colour-bg;
					&:hover {
						color: @navbar-link-colour-hover;
					}
				}
				ul {
					backgrond: @navbar-drop-colour-bg;
					li {
						a{
							color: @navbar-drop-link-colour;
							&:hover {
								color: @navbar-drop-link-colour-hover;
							}
						}		
					}
				}	
			}  
		}
	}
	.wrap {
		@media screen and (max-width: @screen-xs-max) {
			margin-top: 50px !important;
		}
	}
	// HEADER IMAGES
	.head-image {
		padding: 0;
		margin-bottom: 0;
		
		.image-con {
			height: 25%;
			overflow: hidden;
			img {
				margin-top: -10%;
			}
		}
	}

	&.home {
		div.head-image {

			.image-con {
				height: 90%;
				img {
					margin-top: 0;

					@media screen and (max-width: @screen-sm-max) {
						margin-top: 10%;
					}
				}
			}
		}
	}
		
	.main {
		background: url(/wp-content/uploads/2015/11/patterned-bg-800.png);
		margin-top: 5px;
		@media screen and (max-width: @screen-xs-max) {
			//margin-top: 45px;
		}
	}
	
	.head-image + .main {
		margin-top: 0;
	}
	

	// IMAGE GRID...
	.image-grid {
	    margin-left: -5px;
		margin-right: -5px;
		margin-top: -9px;

			@media screen and (max-width: @screen-sm-max) {
				//margin-top: 45px !important;
			}


		.ig-block {
			padding: 0;
			
			
			
			@media screen and (max-width: @screen-xs-max) {	
				&.variable-height {
					display: block;
					height: 100%;
					//min-height: 300px;
					.ig-inner {
						.ig-overlay-con {
							position: relative;
							display: block;
							padding: 24px 0;
							//min-height: 300px;
							.ig-overlay {
								display: block;
								//min-height: 300px;
								top: 0;
								-webkit-transform: translateY(0);
								-ms-transform: translateY(0);
								-o-transform: translateY(0);
								transform: translateY(0);
							}
						}
						img {
							display: none;
						}
					}
				}
			}
			
			.ig-inner {
				position: relative;
				
				.ig-overlay {
					position: absolute;
				
					width: 100%;
					margin: 0 auto;
					text-align: center;

					
					a {
						color: #fff;
						font-size: 2em;
						text-ransform: uppercase;
						
					}
					
					.ig-overlay-bt a {
						margin: 3px 6px;
						text-align: center;
						font-size: 1em;
					}
					
					.reset-style {
						font-size: 1em;			
						text-shadow: none;			
					}
					
					.form-booking, .form-table {
						text-shadow: none;	
						.control-label {
							font-size: 1em;			
							text-shadow: none;			
							display: none;
						}
					}
					
					label {
						font-size: 1em;
					}
				
					#opentable-form-con {
						background-color: @maroon;
						color: #333;
						margin-top: 8px;
						
						
						@media screen and (max-width: @screen-md-max) {	
							//background: #d00;
						}
						@media screen and (max-width: @screen-sm-max) {	
							//background: #0d0;
						
						}
						@media screen and (max-width: @screen-xs-max) {	
							//background: #00d;
							width: 200px;
							margin: 0 auto;
						
						}
						
						.otw-wrapper {
							display: inline-block;
						}
						
						.otw-widget-title {
							display: none;
						}
						
						label {
							color: #fff;
							font-size: 16px;
							text-shadow: none;
							margin-bottom: -14px;
							text-shadow: none;
						}
						
						.otw-input-wrap {
							margin: 0 6px 0 0;
							float: left;
							width: 125px;
							
						}
						input {
							font-size: 12px;
						}
						.btn-default {
							color: inherit;
						}
						.otw-button-wrap {
							padding-top: 0;
							margin-top: -12px;
							@media screen and (max-width: @screen-xs-max) {	
								margin-top: 0;
							}
							clear: both;
						}
						.otw-submit-btn {
							
							&:hover {
								border: #8c8b74 solid 1px;
							}
						}
						.dropdown-menu {
							a {
								padding: 0 6px;
								font-size: 16px;
								color: #442F38;
								text-shadow: none;
							}
						}
						.powered-by-open-table {
							display: none;
						}
						
						.otw-button-wrap {
							input[type='submit'] {
								font-family: @font-stack-1;
								margin: 3px 6px;
								text-align: center;
								font-size: 14px;
								color: #fff;
								background-color: darken(@maroon, 10%);
								border-color: 1px solid lighten(@maroon, 10%);
								//line-height: 2.0;
								//border: 1px solid transparent;
								white-space: nowrap;
								padding: 3px 20px;
								border-radius: 4px;
								text-transform: uppercase;
								box-shadow: none;
								
								color: #ffffff;
								background-color: darken(@maroon, 10%);
								border-color: 1px solid lighten(@maroon, 10%);
								padding: 6px 20px 3px 20px;
								margin: -12px 6px 3px 6px;
								text-align: center;
								font-size: 16px;
								white-space: nowrap;
								line-height: 2.0;
								letter-spacing: 3px;
								font-size: 14px;
								
								@media screen and (max-width: @screen-xs-max) {	
									margin-left: 20px;
								}
							}

						}
						
					}
				
					#booking-form-con {
						background-color: @maroon;
						color: #333;

						.input-group {
						    display: inline-table;
						    vertical-align: middle;
						}
						
						.form-group {
							margin-top: -6px;
							.input-group {
								background: #eee;
								-webkit-border-radius: 2px;
								-moz-border-radius: 2px;
								border-radius: 2px;	

								input {
									padding: 0;
									
									&#arrival_date:before{ 
										content:"Arrvial"; 
										width:100%; 
										color:#aaa; 
									} 
									
									&#departure_date:before{ 
										content:"Departure"; 
										width:100%;
										 color:#aaa; 
									} 
									
									&:focus:before { 
										content:none;
									}
									
									&.changed:before {
										content:'';
										width: 0;
										display: none;
										background: #d0d;
									}
									/*
									& + .input-group-addon {
	
									}*/
								}
							}
						}	
						
						.form-group-clear {
							clear: both;
							float: none;
							display: block;
							margin-top: 8px;
							@media screen and (max-width: @screen-xs-max){
								margin-top: 0;
							}	
						}


						.input-group.date {
							width: 160px;
	
						} 
						.ig-overlay-bt {

							button {
								margin: 3px 6px;
							    text-align: center;
							    font-size: 12px;
							    letter-spacing: 3px;
						        padding: 8px 20px 6px;
							}

						}
					}
				}
				
			}
			
			&.elem-fragment_form_table {
				.ig-inner {
					.ig-overlay {

						@media screen and (max-width: @screen-md-max) {	
							width: 85%;
							.otw-input-wrap {
								width: 115px !important;
							}
						}
						@media screen and (max-width: @screen-sm-max) {	
							width: 76%;
						}
						@media screen and (max-width: @screen-xs-max) {	
							#opentable-form-con {
								
								.otw-input-wrap {
									float: none;
									height: 35px;
									label {
										float: left;
										padding-top: 4px;
										text-align: right;
									}
									input, select, button {
										margin-left: 100px;
										margin-top: -20px;
									}
									
								}
								.otw-button-wrap {
									padding-top: 0;
									padding-left: 67px;
									text-align: right;
									.otw-submit-btn {
										//padding: 3px 6px;
										margin-top: 12px;
										font-size: 10px;
										
									}
								}
							}
						}
					}
				}				
			}
		}	
	}
	
	
	// OWL CAROUSEL 
	
	
	
	.owl-carousel .owl-controls .owl-nav {
		text-align: center;
		.owl-prev, .owl-next {
			color: #fff;
			font-size: 14px;
			margin: 5px;
			padding: 4px 7px;
			background: #d6d6d6;
			display: inline-block;
			cursor: pointer;
			-webkit-border-radius: 3px;
			-moz-border-radius: 3px;
			border-radius: 3px;	
		}
	}

	// TABBED CONTENT ELEMENT

	.main ul.nav-pills{
		display: inline-block;
	}
	.page-block {
		&.tabbed-content {
			.row {
				.inner {
					width: 600px;
					margin: auto;
				}
			}
		}	
	}
}


body.admin-bar .banner {
	margin-top: 32px !important;
}


// #####################################################################
// Gravity Form
// #####################################################################

.gform_wrapper {
    overflow: inherit;
    margin: auto;
    max-width: 50%;

    .gform_heading {
	    width: 100%;
	    margin-bottom: -0.875em;
	    margin: auto;
	    text-align: center;
	}
	.top_label input.medium, .gform_wrapper .top_label select.medium {
    	width: 98%;
	}
}




// #####################################################################
// BUTTONS
// #####################################################################

html {
	body {
		.main { 
			a.btn, a.btn-primary {
				color: @white;
				background-color: darken(@maroon, 10%);
				line-height: 2.0;
				margin: 3px 6px;
			    text-align: center;
			    font-size: 14px;
			    border: 1px solid lighten(@maroon, 10%);
			    white-space: nowrap;
			    padding: 8px 20px 6px 20px;    
			    color: #fff;
			    padding-left: 33px;
			    padding-right: 34px;
			    font-family: Josefin Sans,sans-serif;
			    text-transform: uppercase;
			    letter-spacing: 3px;

		 	}   
		}	    					
	}
}



// #####################################################################
// CUSTOME>LEZZ
// #####################################################################


html{}



// ##############
// !GOBAL STYLES
// ##############

.container-full {
	margin: 0 auto;
	width: 100%;
	//background: url(http://local.tunbridgewellshotel.com/wp-content/uploads/2015/09/light_grey.png);

}

// ##############
// !KENT-STYLE
// ##############

body.contact { 
	.main { 
		padding-left: 0; 
		padding-right: 0; 
		} 
	}
body.home { 

	}

body.post-type-archive-tribe_events,
body.single-tribe_events {
	.main {
		width: 100%;
	}
}


// Base styles for page elements

body{
background: @white;


	.container-full {
  	margin: 0 auto;
  	width: 100%;
  	margin-bottom: -20px; // trying to get the padding on the bottom with a fixed border.
  	// ### background: url(/wp-content/uploads/2015/09/light_grey.png);
	}


	h1, h2, h3, h4, h5, h6 {
		font-family: @font-stack-3;
		color: @mid-grey;
	}
	
	p, div, span, a {
		
	}
	
	h2 {
		font-size: 26px;
		color: @mid-grey;
		font-style: italic;
		line-height: 35px;
	}
	
	h3 {
		color: #333;
		padding-bottom: 10px;
		line-height: 1em;
		font-weight: 500;
	}
	
	
	p {
		color: @black;
		font-size: 14px;

	}
	hr.style-two {
		width: 60%;
	    border: 0;
	    height: 1px;
	    background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0));
	} 

	.content-info{
		background-color: @white;
	}

// Headder edits	
	.navbar-default {
		border-color: @white;
		background-color: @white;
	}
	
	.page-block-center {
		width: 1080px;
		margin: 0 auto;
		padding: 0px 0 0 0;
	}

	.page-header {
		display: none;
	}
	
	.banner {
		position: fixed;
		top: 0;
		width: 100%;
		z-index: 2000;


		.container {
			margin: 0 auto;
			//width: 100%;
		}
		a.navbar-brand {
			background: none;
			font-family: @font-stack-3; 
			font-size: 2.5em;
			color: @grey-dark;
			width: auto;
			height: auto;
			text-indent: 20px;
			padding: 15px 0;
		}
		.navbar-nav {
			//float: right;
			margin: 0;

			a{
				font-family: @font-stack-1;
				text-transform: uppercase;
				font-weight: bold;
				font-size: 1em;
				color: @grey-dark !important;
				//padding-left: 10px;
				//padding-right: 10px;

			}
		}
	}


// Page edits

	.image-grid {

		.ig-block {

			.image-border:before {
			    content: '';
			    position: absolute;
			    top: 0;
			    left: 0;
			    right: 0;
			    bottom: 0;
			}
		
			.ig-inner {	
				text-align: center;
				
				.button-cont {
					position: absolute;
					bottom: -39px;
					right: 0px;
					
					.ig-cust-bt {
							font-family: 'Garamond'; 
							color: @grey-light !important;
							padding-top: 0.4em;
							font-size: 1.0em;	
							font-style: italic;
							float: left;
							margin-left: 3px;
					}
				}
				
				
				.ig-overlay-con {
					position: absolute;
					width: 100%;
					height: 100%;
					vertical-align: middle;
					-webkit-transform-style: preserve-3d;
					-moz-transform-style: preserve-3d;
					transform-style: preserve-3d;
					overflow: visible;
					z-index: 10;
				}
				
				.ig-overlay {
					
					position: relative;
					z-index: 11;

					top: 50%;
					-webkit-transform: translateY(-50%);
					-ms-transform: translateY(-50%);
					transform: translateY(-50%);
					
					background-color: fade(@maroon, 50%);
					width: 70%;
					font-family: @font-stack-3;
					// // top: 32% !important;
					// // left: 15%;
					margin: 0 auto;
					color: #F0F0F0;
					font-size: 2.8em;
					text-shadow: 1px 1px 1px @grey-dark;
					}

				.ig-overlay-st {
					font-family: @font-stack-3;
					font-size: 0.5em;
					color: @yellow;	
					}

				.ig-overlay-bt {
					font-family: @font-stack-1;
					padding-top: 0.3em;
					font-size: 0.275em;	
					letter-spacing: 2px;
					text-transform: uppercase;
				    padding-bottom: 0.4em;
					}

				.btn-primary, .comment-form input[type=submit] {
	  				color: @white;
					background-color: darken(@maroon, 10%);
					border-color: lighten(@maroon, 10%);
					line-height: 2.0;
					}

				hr {
				    display: block;
				    height: 1px;
				    width: 60%;
				    border: 0;
				    border-top: 1px solid #ccc;
				    margin: auto;
				    padding: 0;
					}		
			}
		}
	}
	
// Bedroom pages


	@media @large {
		.head-image .image-con {
			margin-top: 40px;
			overflow: hidden;
		}
	}

	div.head-image {
	    padding: 0;
	    margin-bottom: 0;
	    width: 100%;
	    height: 80%;
	    margin: auto;
	    margin-bottom: 0px;
	}
	.jumbotron{
		background-color: @white;
	}


	.main {
		text-align: center;
		ul, ol {
			text-align: left;
		    margin-top: 35px;
		    margin-bottom: 10px;
		}

		.tabbable{
			width: 60%;
			margin: auto;
			background: @white;
			//padding: 20px;
		}

		.tab-content>.active {
			display: block;
			padding: 20px;
		}
	

		.floorplan {
		    background: transparent url("/wp-content/uploads/2015/09/white-circle.png") no-repeat center center;
		    position: relative;
		    margin: 30px 0 30px;
		    height: 620px;
		
			.alignnone {
			    margin-left: 0;
			    margin-right: 0;
			    max-width: 100%;
			    margin-top: 80px;
			}
		}
		.btn-default {
			color: @white;
			background-color: @maroon;
			border-color: lighten(@maroon, 10%);
		}
		.btn, .comment-form input[type=submit] {
		    border: 1px solid transparent;
		    white-space: nowrap;
		    padding: 3px 20px;
		    font-size: 18px;
		    line-height: 1.42857143;
		    border-radius: 4px;
		}			
	}

// post/special offer edits.

.sidebar-primary .main {
	    width: 100%;
	}

	.offer-con{
		width: 80%;
		margin: auto;
		margin-top: 60px;
	}

.offer-thumb-con {
	img{
		width: 80%;
		height: auto;
		padding-bottom: 5px;
	}
}

	article{
		header {
			h1 {
			margin-top: 60px;
			text-align: center;
			widows: 60%;
			}
			.author{
				display: none;
			}
			.updated {
				display: none;
			}
		}
	}

// Events edits 

	#tribe-events .tribe-events-button{
			display: none;
			}		
	.tribe-events-event-image{
		text-align: center !important;
	}
	.tribe-events-back {
	    margin: 20px 0 20px;
	}
	.sidebar {
		display: none;
	}

	.tribe-bar-is-disabled .tribe-events-page-title{
		text-align: center !important;
	}
	#tribe-events-content-wrapper{

			
		#tribe-bar-form{
			margin-top: 50px;
		}
		.sidebar {
			display:none;
		}
		.main {
			width: 100%;
		}

		.tribe-events-list{
			width: 100% !important;
		}
		#tribe-events-content{
			width: 60%;
			margin: auto;
			padding-top: 15px;

			a{
				color: @mid-grey;
			}
		}
		
		.tribe-events-button{
			display: none;
		}
	}
	
//MENU
//	Move to _menus.less at some stage...
//

		.menu-block {
			
			h1 {
				color: @grey-dark;
			}
			h4 {
				font-style: italic;
					color: @grey-dark;
			}
			
			.menu-item-block {

				&.course-title{

					hr.style-two {
						width: 60%;
					    border: 0;
					    height: 1px;
					    background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0));
					} 
					h3{
						text-decoration: underline;
						//font-style: italic;
						color: @grey-dark;
					}
					
				}

				&.course-subtitle {
					
				}
				&.menu-item {
					

					h4 {
						color: @grey-dark;
						//font-style: normal;

					}
					h5 {
						font-size: 1em;
						color: @grey-dark;
					}
					.menu-item-price{
						color: @grey-dark;
					}
					.menu-item-notes {
						&.menu-item-notes-vegeterian {
							
						}
						&.menu-item-notes-vegan {
							
						}
						&.menu-item-notes-nuts {
							padding-bottom: 10px;
						}
					}
				}
				&.note h5 p{
					color: @grey-dark;
				}
			}
		}
		

		ul .nav .nav-pills{
			margin: auto;
		}

.content-info {
	.container {
		background-color: @maroon;
	}
}

 
// FOOTER
	
	.content-info {
		margin-top: 20px; 
		
		.container {
			padding: 12px;
		}	
	}

		.container {
			width: 90%;
			padding-left: 0px;
			padding-right: 0px;
					
			
				.footer-page{
					margin-bottom: 12px;
					margin-top: -12px;
					margin-left: 0;
					margin-right: 0;
					padding-bottom: 24px;
					
					.f-box{
						border-color: @black;
						border-width: 2px;
						a {
						    display: block;
						    text-align: center;
						    padding: 40px 0;
						    font-family: @font-stack-1;
						    font-weight: 400;
						    font-size: 14px;
						    letter-spacing: 3px;
						    text-transform: uppercase;
						    color: #fff;
						    //border: 1px solid rgba(255, 255, 255, 0.06);
						    &:hover, &:focus {
							    background: lighten(@maroon, 10%);
						    }
						}
					}
					.btn-default {
						color: @white;
						background-color: @maroon;
						border-color: lighten(@maroon, 10%);
						}

					#social ul {
						margin: 0;
						margin-bottom: -15px;
						padding: 20px;
						padding-bottom: 0px;
						list-style-type: none;
						text-align: center;
					    //display: inline !important; 
					}

					#social ul li { 
						display: inline !important; 
					}

					#social ul li a {
						text-decoration: none;
						padding: .2em 1em;
						display: inline !important; 
						//color: #fff;
						//background-color: #036;
					}

					#social ul li a:hover{
						color: #fff;
						//background-color: #369;
					}

					.col-md-4 .col-left img{
						float: right;
					}
					
//copy right section in footer

					&.copyright{
						margin-bottom: 30px;
						padding-top: 9px;
						background-color: @maroon - #222;
						color: @mid-grey;

						.xcvi-brand{
							text-align: left;
						
							@media screen and (max-width: @screen-xs-max) {
								text-align: center;
								float: none;
							}
						}

						.copyrt{
							text-align: right;

							@media screen and (max-width: @screen-xs-max) {
								text-align: center;
								float: none;
							}
						}

						p {
							color: @mid-grey;
							
						}
					}
				}
		

// Fixed Footer...

			.footer-fixed{
				display: block;
				position: fixed;
				z-index: 9999;
				width: 100%;
				bottom: -10px;
				left: 0px;
				margin-bottom: 0;
				background-color: #fff;
				.row {
					width: 90%;
					margin: 0 auto;
					
					div {
						padding: 0;
					}

					a {
						margin-top: 12px;
						margin-bottom: 25px;
						padding: 10px;
					}
					.btn-primary {
						float: right;
					    color: #fff;
					    padding-left: 20px;
					    padding-right: 20px;
					    font-family: @font-stack-1;
					    text-transform: uppercase;
					    letter-spacing: 3px;
					    background-color: @maroon;
						border-color: lighten(@maroon, 10%);

						.btn-left{
							float: right;
						}
					}
				}	
			}
		}
	
//! mailchimp styling

	.container {
		.footer-page {
			margin-left: -12px;
			margin-right: -12px;

			#mc_embed_signup {
				padding: 10px;
				background:transparent; 
				text-align: center;
				font:12px @font-stack-1; 
				text-transform: uppercase;
				//color: @grey-light;

				input.email{
					background-color: @grey-light !important;
					margin: auto !important;
				}

				label {
				    display: block;
				    text-align: center;
				    font-size: 1em;
				    color: @white;
				    padding-bottom: 10px;
				    letter-spacing: 3px;
				    font-weight: 400;
				}

				.email{
					margin: auto;
				}
				.button{
					margin:auto !important;
					margin-top: 10px;
					text-transform: uppercase;
					color: @white;
					font-size: 1em;
					font-weight: normal;
					letter-spacing: 3px;
					background-color: darken(@maroon, 10%);
					border-color: lighten(@maroon, 10%);
				}
				.clear {
					display: block;
					margin-top: 12px;
				}
			}
		}
	}
	.nav-pills{
		li{
			a {
			    color: @grey-dark;
			}
		}
	}
	.tab-content{
		a {
			color: @grey-dark;
		}
	}
}	



@media screen and (max-width: @screen-xs-max) {
	.open-table-widget-datepicker {
		margin-left: -97px;
	    margin-top: -52px;
	}
}

.u-hide {
	display: none;
}
.u-block {
	display: block;
}





